import React, { useState } from 'react';
import { Input, Button, Flex, Select, ColorPicker } from 'antd';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useCreateStatusMutation } from 'modules/CRMModule/api/crm.api';
import Ninja from '@ninja';

const CreateStatus = (props) => {
  const { flow_id, setAddStatus } = props;
  const [createStatus, { isLoading: loadingStatusCreate }] = useCreateStatusMutation();

  const [colorHex, setColorHex] = useState('#000000');
  const [formatHex, setFormatHex] = useState('hex');
  const [statusName, setStatusName] = useState('');
  const [group, setGroup] = useState('');

  const hexString = React.useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const addNewStatus = () => {
    createStatus({
      flow_id: flow_id,
      name: statusName,
      color: hexString,
      group: group,
    })
      .unwrap()
      .then(() => {
        setStatusName('');
        setColorHex('#000000');
        setGroup('');
        setAddStatus(false);
      });
  };

  const statusGroups = [
    { label: 'Default', value: 'default' },
    { label: 'Active', value: 'active' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Done', value: 'done' },
  ];

  return (
    <div>
      <Flex align='center' gap='small'>
        <ColorPicker
          size='medium'
          format={formatHex}
          value={colorHex}
          onChange={setColorHex}
          onFormatChange={setFormatHex}
          destroyTooltipOnHide={true}
        />
        <Input
          value={statusName}
          className='w-full'
          size='medium'
          onChange={(e) => {
            setStatusName(e.target.value);
          }}
          placeholder='Status Name'
        />
        <Select
          className='min-w-52'
          onChange={(val) => setGroup(val)}
          placeholder='Select Group'
          options={statusGroups}
        />
        <Button
          className='text-xs'
          type='primary'
          onClick={addNewStatus}
          loading={loadingStatusCreate}
        >
          Save
        </Button>
        <Button
          type='text'
          onClick={() => setAddStatus(false)}
          icon={<Icon path={mdiClose} size={1} color={Ninja.colors.light} />}
        />
      </Flex>
    </div>
  );
};

CreateStatus.requiredParams = {
  flow_id: (p) => parseInt(p) > 0,
};

export default CreateStatus;
