import React, { useEffect } from 'react';
import { Select, Radio, Input, Flex, List, Button } from 'antd';
import { currency_options } from '@ninja/consts';
import { useConfigDealValueMutation } from 'modules/CRMModule/api/crm.api';
import { useFormik } from 'formik';
import ninjaApi from 'store/redux/apis';
import useQueryParams from 'hooks/useQueryParams';
import { snackbar } from 'components/notifications/notifications';

const ValueConfiguration = (props) => {
  const [configDealValue, { isLoading }] = useConfigDealValueMutation();
  const { searchParams } = useQueryParams();
  const { tab } = searchParams;
  const [getGetDealValueQuery, { data: data = {}, isLoading: dataLoading }] =
    ninjaApi.useLazyGetDealValueQuery();

  const handleConfigure = () => {
    configDealValue({
      conversion_value: form.values.conversion_value,
      profit_type: form.values.profit_type,
      value_count: form.values.value_count,
      value_type: form.values.value_type,
    })
      .unwrap()
      .then(() => {
        snackbar.success('Configuration Updated');
        props.onClose();
      });
  };

  const form = useFormik({
    initialValues: {
      conversion_value: data?.data?.conversion_value,
      profit_type: data?.data?.profit_type,
      value_count: data?.data?.value_count,
      value_type: data?.data?.value_type,
      currency: data?.data?.currency,
    },
    onSubmit: handleConfigure,
  });

  useEffect(() => {
    if (tab === 'value') {
      getGetDealValueQuery()
        .unwrap()
        .then((data) => {
          form.setValues({
            conversion_value: data?.data?.conversion_value,
            profit_type: data?.data?.profit_type,
            value_count: data?.data?.value_count,
            value_type: data?.data?.value_type,
            currency: data?.data?.currency,
          });
        });
    }
  }, [tab]);

  return (
    <div className='mt-6'>
      <Flex vertical gap='large'>
        <Flex align='center' gap='small' justify='space-between'>
          <List.Item.Meta
            title='Choose Deal Profit'
            description={<span className='text-sm'>Profit for each deal</span>}
          />
          <Flex align='center' gap='small'>
            <Select
              className='w-40'
              value={
                form.values.profit_type === 'fixed-percent' ? 'Fixed' : form.values.profit_type
              }
              size='large'
              onChange={(val) => form.setFieldValue('profit_type', val)}
              options={[
                {
                  value: 'manual',
                  label: 'Manual',
                },
                {
                  value: 'fixed',
                  label: 'Fixed',
                },
              ]}
            />
            {form.values.profit_type !== 'manual' && (
              <Flex align='center' gap='small'>
                <Radio.Group
                  className='grid'
                  size='small'
                  name='radiogroup'
                  value={form.values.profit_type}
                  onChange={(e) => {
                    form.setFieldValue('profit_type', e.target.value);
                  }}
                >
                  <Radio value='fixed'>{currency_options[form.values.currency]?.symbol}</Radio>
                  <Radio value='fixed-percent'>%</Radio>
                </Radio.Group>
                <Input
                  value={form.values.conversion_value}
                  onChange={(e) => form.setFieldValue('conversion_value', Number(e.target.value))}
                  placeholder='Value'
                  type='number'
                  className='text-sm'
                  size='large'
                  prefix={
                    form.values.profit_type === 'fixed-percent'
                      ? '%'
                      : currency_options[form.values.currency]?.symbol
                  }
                />
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex align='center' gap='small' justify='space-between'>
          <List.Item.Meta
            title='Choose Deal Value'
            description={<span className='text-sm'>Value for each deal</span>}
          />
          <Flex align='center' gap='small'>
            <Select
              className='w-40'
              value={form.values.value_type}
              size='large'
              onChange={(val) => form.setFieldValue('value_type', val)}
              options={[
                {
                  value: 'manual',
                  label: 'Manual',
                },
                {
                  value: 'fixed',
                  label: 'Fixed',
                },
              ]}
            />
            {form.values.value_type === 'fixed' && (
              <Flex align='center' gap='small'>
                <Input
                  value={form.values.value_count}
                  onChange={(e) => {
                    form.setFieldValue('value_count', Number(e.target.value));
                  }}
                  placeholder='Profit Value'
                  type='number'
                  className='text-sm'
                  size='large'
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Button
          onClick={handleConfigure}
          loading={isLoading}
          size='large'
          type='primary'
          className='w-fit flex items-center justify-center m-auto'
        >
          Configure
        </Button>
      </Flex>
    </div>
  );
};

export default ValueConfiguration;
