import ninjaApi from 'store/redux/apis/ninja.api';
import { invalidatesTagsOfSingleResource } from 'store/redux/utils/rtk-tag-utils';

export const crmApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Contacts', 'Deals', 'CrmForms', 'Form', 'Flow'],
  endpoints: (builder) => ({
    // get crm forms
    getCrmForms: builder.query({
      query: () => ({
        url: '/crm/form/get',
        method: 'POST',
      }),
      providesTags: ['CrmForms'],
    }),

    // CRM Contacts
    getContacts: builder.query({
      query: (body) => ({
        url: '/crm/contact/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Contacts'],
    }),

    addContactsComment: builder.mutation({
      query: (body) => ({
        url: '/crm/contact/comment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),

    getContactsComments: builder.query({
      query: (body) => ({
        url: '/crm/contact/comments',
        method: 'POST',
        body,
      }),
    }),

    updateContact: builder.mutation({
      query: (body) => ({
        url: '/crm/contact/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),

    getSingleContact: builder.query({
      query: (body) => ({
        url: '/crm/contact/find',
        method: 'POST',
        body,
      }),
    }),

    deleteContact: builder.mutation({
      query: (body) => ({
        url: '/crm/contact/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),

    createContact: builder.mutation({
      query: (body) => ({
        url: '/crm/contact/store',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),

    // CRM Deals
    crmDealList: builder.query({
      query: (body) => ({
        url: '/crm/deal/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Deals'],
    }),

    addDealsComment: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/comment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Deals'],
    }),

    getDealsComments: builder.query({
      query: (body) => ({
        url: '/crm/deal/comments',
        method: 'POST',
        body,
      }),
    }),

    updateDeal: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Deals'],
    }),

    getSingleDeal: builder.query({
      query: (body) => ({
        url: '/crm/deal/find',
        method: 'POST',
        body,
      }),
    }),

    deleteDeal: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Deals'],
    }),

    createDeal: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/store',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Deals'],
    }),

    // create contact and deal from lead data
    leadToContact: builder.mutation({
      query: (body) => ({
        url: '/crm/lead/create_contact',
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTagsOfSingleResource('CrmLead', {
        id_key: 'lead_id',
      }),
    }),

    leadToDeal: builder.mutation({
      query: (body) => ({
        url: '/crm/lead/create_deal',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { lead_id }) => [
        { type: 'CrmLead', id: lead_id },
        'Deals',
        'CrmForms',
      ],
    }),

    // deal status
    dealStatusList: builder.query({
      query: (body) => ({
        url: '/crm/deal/status/list',
        method: 'POST',
        body,
      }),
      providesTags: ['DealsStatus'],
    }),

    flowList: builder.query({
      query: (body) => ({
        url: 'crm/deal/status/list_flows',
        method: 'POST',
        body,
      }),
      providesTags: ['Flow'],
    }),

    createFlow: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/status/create_flow',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Flow'],
    }),

    deleteFlow: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/status/delete_flow',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Flow'],
    }),

    updateFlow: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/status/update_flow',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Flow'],
    }),

    createStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/status/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DealsStatus'],
    }),

    deleteStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/status/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DealsStatus'],
    }),

    updateStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/deal/status/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DealsStatus'],
    }),

    //  config deal value and profit
    configDealValue: builder.mutation({
      query: (body) => ({
        url: 'crm/deal/update_value',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Deals'],
    }),

    getDealValue: builder.query({
      query: (body) => ({
        url: 'crm/deal/get_value',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Deals'],
    }),
  }),
});
export const {
  // contacts
  useGetContactsQuery,
  useAddContactsCommentMutation,
  useGetContactsCommentsQuery,
  useUpdateContactMutation,
  useGetSingleContactQuery,
  useDeleteContactMutation,
  useCreateContactMutation,
  useLeadToContactMutation,
  useLeadToDealMutation,
  // deals
  useCrmDealListQuery,
  useAddDealsCommentMutation,
  useGetDealsCommentsQuery,
  useUpdateDealMutation,
  useGetSingleDealQuery,
  useDeleteDealMutation,
  useCreateDealMutation,
  // deal status
  useDealStatusListQuery,
  useCreateStatusMutation,
  useDeleteStatusMutation,
  useUpdateStatusMutation,
  // flow
  useFlowListQuery,
  useCreateFlowMutation,
  useDeleteFlowMutation,
  useUpdateFlowMutation,

  useConfigDealValueMutation,
  useGetDealValueQuery,
  // forms
  useGetCrmFormsQuery,
} = crmApi;
