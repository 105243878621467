import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { useDeleteStatusMutation, useUpdateStatusMutation } from 'modules/CRMModule/api/crm.api';
import ninjaApi from 'store/redux/apis';
import { Button, Flex, ColorPicker, List, Spin, Typography, Select } from 'antd';
import { mdiPlus, mdiClose, mdiPencil, mdiTrashCanOutline, mdiContentSave } from '@mdi/js';
import Ninja from '@ninja';
import { LoadingOutlined } from '@ant-design/icons';
import CreateStatus from './CreateStatus';

const DealStatuses = ({ flow_id }) => {
  const [deleteStatus, { isLoading: deleteLoading }] = useDeleteStatusMutation();
  const [updateStatus, { isLoading: updateLoading }] = useUpdateStatusMutation();
  const [getDealStatusListQuery, { data = [], isLoading }] = ninjaApi.useLazyDealStatusListQuery();

  const [addStatus, setAddStatus] = useState(false);
  const [colorHex, setColorHex] = useState('#000000');
  const [formatHex, setFormatHex] = useState('hex');
  const [editingStatusId, setEditingStatusId] = useState(null);
  const [tempStatus, setTempStatus] = useState('');
  const [group, setGroup] = useState(null);
  const [deletingStatusId, setDeletingStatusId] = useState(null);

  const hexString = React.useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  useEffect(() => {
    getDealStatusListQuery({ deal_status_flow_id: flow_id });
  }, [flow_id]);

  const updatingStatus = (id) => {
    updateStatus({
      id: id,
      name: tempStatus,
      flow_id,
      color: hexString,
      group: group,
    })
      .unwrap()
      .then(() => {
        setEditingStatusId(null);
        setTempStatus('');
      });
  };

  const handleEditClick = (item) => {
    setEditingStatusId(item.id);
    setTempStatus(item.name);
    setColorHex(item.color);
  };

  useEffect(() => {
    getDealStatusListQuery({ deal_status_flow_id: flow_id });
  }, [flow_id]);

  const statusGroups = [
    { label: 'Default ', value: 'default' },
    { label: 'Active', value: 'active' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Done', value: 'done' },
  ];

  if (isLoading) {
    return (
      <Spin indicator={<LoadingOutlined spin />} className='m-x-auto mt-6 w-full' size='large' />
    );
  }

  return (
    <div className='grid gap-2'>
      {data?.data?.length ? (
        <div className='rounded-md grid gap-2'>
          <List className='statusList'>
            {data.data?.map((status) => {
              return (
                <List.Item
                  key={status.is}
                  className='bg-white rounded-md'
                  actions={[
                    <Flex align='center' gap='small' key='action'>
                      {editingStatusId === status.id ? (
                        <Flex align='center' gap='small' key='action'>
                          <Button
                            shape='circle'
                            key='list-loadmore-save'
                            type='text'
                            onClick={() => updatingStatus(status.id)}
                            loading={updateLoading}
                            icon={<Icon path={mdiContentSave} color={Ninja.colors.orange} />}
                          />
                          <Button
                            shape='circle'
                            size='small'
                            type='text'
                            key='list-loadmore-close'
                            onClick={() => setEditingStatusId(false)}
                            icon={<Icon path={mdiClose} color={Ninja.colors.light} />}
                          />
                        </Flex>
                      ) : (
                        <Button
                          shape='circle'
                          key='list-loadmore-edit'
                          type='text'
                          onClick={() => handleEditClick(status)}
                          icon={<Icon path={mdiPencil} color={Ninja.colors.light} />}
                        />
                      )}
                    </Flex>,
                    <Button
                      shape='circle'
                      key='list-loadmore-delete'
                      type='text'
                      onClick={(id) => {
                        setDeletingStatusId(id);
                        deleteStatus({ deal_status_id: status.id })
                          .unwrap()
                          .finally(() => setDeletingStatusId(null));
                      }}
                      loading={deletingStatusId === status.id && deleteLoading}
                      icon={<Icon path={mdiTrashCanOutline} color={Ninja.colors.light} />}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <ColorPicker
                        size='small'
                        value={editingStatusId === status.id ? colorHex : status.color}
                        disabled={editingStatusId !== status.id}
                        format={formatHex}
                        onChange={setColorHex}
                        onFormatChange={setFormatHex}
                        destroyTooltipOnHide={true}
                      />
                    }
                    title={
                      <Flex align='center' gap='middle'>
                        <Typography.Title
                          style={{ color: status.color }}
                          level={5}
                          className='!my-0 !ml-4 text-sm'
                          editable={
                            editingStatusId === status.id && {
                              icon: null,
                              onChange: (newTitle) => setTempStatus(newTitle),
                              enterIcon: null,
                              editing: true,
                              autoSize: { minRows: 1 },
                            }
                          }
                        >
                          {editingStatusId === status.id ? tempStatus : status.name}
                        </Typography.Title>
                        {editingStatusId === status.id ? (
                          <Select
                            size='medium'
                            className='w-44'
                            defaultValue={status.group}
                            onChange={(val) => setGroup(val)}
                            options={statusGroups}
                            placeholder='Select Status'
                          />
                        ) : (
                          <span className='text-xs text-color-light'>{status.group}</span>
                        )}
                      </Flex>
                    }
                  />
                </List.Item>
              );
            })}
          </List>
        </div>
      ) : null}
      <div className='mt-5'>
        {addStatus ? (
          <CreateStatus flow_id={flow_id} setAddStatus={setAddStatus} />
        ) : (
          <Button
            size='medium'
            className='w-fit m-auto'
            onClick={() => setAddStatus(true)}
            icon={<Icon path={mdiPlus} />}
          >
            Create Status
          </Button>
        )}
      </div>
    </div>
  );
};

export default DealStatuses;
