import '@ninja';
import rootRoutes from 'router/rootRoutes';
import 'react-data-grid/lib/styles.css';
import { renderRoutes } from 'router/renderRoutes';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { ninjaEvents } from 'tracking/ninjaEvents';
import useAuth from 'utils/auth/useAuth';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const App = () => {
  useBootstrapUser();
  const history = useHistory();

  const { user } = useAuth();
  const { workspace } = useWorkspace();

  useEffect(() => {
    ninjaEvents.emit('app_initialized');

    history.listen(() => {
      ninjaEvents.emit('pageview', { location, user, workspace });
    });
  }, []);

  return renderRoutes(rootRoutes);
};

export default App;
